import React, { useEffect, useState } from "react"

import Layout from "../layouts"
import { Box, Flex, Text, Spinner } from "theme-ui"
import Image from "../new-ui/image"
import { useStore } from "../../context/NewStoreContext"
import { getVariantPrice, formatMoneyAmount } from "../../utils/prices"
import Medusa from "../../services/api"
import { navigate } from "gatsby-link"
import {
  handleUrlPrefix,
  simpleWordTranslate,
} from "../../utils/translations-util"
import useDrawerHook from "../../hooks/useDrawerHook"
import { trackRemoveFromWishlist } from "../../services/analytics"
import Button from "../new-ui/button"
import AddToCart from "../new-ui/cart/add-to-cart"
import { Icon } from "../v2/ui/Icon"
import { useInterfaceContext } from "../../context/InterfaceContext"
import { useAccountContext } from "../../context/AccountContext"

import styled from "@emotion/styled"

const Container = styled(Flex)`
  padding-top: 70px;
  flex-direction: column;

  > div:last-of-type {
    padding-top: 24px;

    ${(props) =>
      !props.noPaddingMobile &&
      `

      padding-left: 16px;
      padding-right: 16px;
    `}
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    flex-direction: row;
    padding-top: 0px;

    > div {
      flex: 1;
    }

    > div:first-of-type {
      padding-top: 130px;
    }

    > div:last-of-type {
      padding-top: 130px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  ${(props) => props.theme.breakpointsLegacy.hd} {
    flex-direction: row;
    padding-top: 0px;

    > div {
      flex: 1;
    }

    > div:first-of-type {
      padding-top: 130px;
    }

    > div:last-of-type {
      padding-top: 130px;
      padding-left: 130px;
      padding-right: 130px;
    }
  }
`

const EmptyWishlist = styled.div`
  margin-top: 40px;
`

const Wishlist = () => {
  const [items, setItems] = useState([])
  const { drawerContent, drawerVisible } = useDrawerHook({
    config: { asModal: true },
  })

  const { setWishlistCount } = useInterfaceContext()
  const { id, metadata, loggedIn } = useAccountContext()

  const [loading, setLoading] = useState(true)

  const removeFromWishList = ({ variantId }) => {
    let wishlistItems = JSON.parse(
      localStorage.getItem("medusa::cache::wishlist")
    )

    if (wishlistItems && wishlistItems.length) {
      wishlistItems = wishlistItems.filter(
        (item) => item.variantId !== variantId
      )

      localStorage.setItem(
        "medusa::cache::wishlist",
        JSON.stringify(wishlistItems)
      )

      updateWishlist()
    }
  }

  const updateWishlist = () => {
    let wishlistItems

    if (id) {
      wishlistItems = metadata?.wishlist
    } else {
      wishlistItems = JSON.parse(
        localStorage.getItem("medusa::cache::wishlist")
      )
    }

    setWishlistCount(wishlistItems !== null && wishlistItems.length)

    if (wishlistItems && wishlistItems.length) {
      Medusa.variants
        .list({
          ids: wishlistItems.map((wish) => wish.variantId).join(),
        })
        .then(({ data }) => {
          const items = data.variants.map((variant) => {
            return {
              ...variant,
              thumbnail: variant?.product?.thumbnail,
              metadata: variant.metadata,
              title: variant.title,
              description: variant.description,
            }
          })

          setItems(items)
          setLoading(false)
        })
        .catch((err) => console.error(err))
    } else {
      setItems([])
      setLoading(false)
    }
  }

  useEffect(() => {
    if (loggedIn) {
      navigate("/account/my-wishlist")
    }

    const load = async () => {
      updateWishlist(items, setItems)
    }

    load()
  }, [])

  return (
    <Layout>
      <Container>
        <Box sx={{ width: "100%" }}>
          <Flex
            sx={{ justifyContent: "space-between", alignItems: "flex-end" }}
          >
            <Text sx={{ fontSize: "21px" }}>Wishlist</Text>
          </Flex>
          {loading ? (
            <Flex
              sx={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                my: 2,
              }}
            >
              <Spinner />
            </Flex>
          ) : (
            <>
              <EmptyWishlist>
                <p>
                  {items.length
                    ? `Keep track of your favorite pieces no matter the browser or device, all in one place`
                    : `Looking for your wishlist? Sign in to pick up where you left
                  off`}
                </p>
                <Button onClick={() => navigate("/account/sign-in")}>
                  Sign in
                </Button>
              </EmptyWishlist>
              <WishListItems
                items={items}
                removeFromWishList={removeFromWishList}
              />
            </>
          )}
          {drawerVisible && drawerContent}
        </Box>
      </Container>
    </Layout>
  )
}

export const WishListItems = ({ items, sx, removeFromWishList }) => {
  const { cart } = useStore()

  return (
    <Flex sx={{ flexWrap: "wrap", gap: "12px", my: 4, ...sx }}>
      {items.map((item) => {
        const handle = item.product?.handle

        return (
          <Flex
            key={item.id}
            className="item"
            sx={{
              flexDirection: "column",
              flex: ["1 0 45%", "1 0 30%"],
              maxWidth: ["100%", "33%"],
              mb: [3, 4],
              justifyContent: "space-between",
            }}
          >
            <Flex sx={{ flexDirection: "column" }}>
              <Flex sx={{ position: "relative" }}>
                {item.product?.thumbnail?.gatsbyImageData ? (
                  <Image
                    image={item.product?.thumbnail?.gatsbyImageData}
                    onImageClick={() => {
                      navigate(`/product/${handle}`)
                    }}
                  />
                ) : (
                  <Image
                    src={item.product?.thumbnail}
                    onImageClick={() => {
                      navigate(`/product/${handle}`)
                    }}
                  />
                )}
                {removeFromWishList && (
                  <Flex
                    sx={{
                      position: "absolute",
                      bottom: "auto",
                      right: "0",
                      margin: 3,
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      trackRemoveFromWishlist({
                        product_id: item.product_id,
                        sku: item.sku,
                        variant_id: item.id,
                      })
                      removeFromWishList({
                        variantId: item.id || item.variant_id,
                      })
                    }}
                  >
                    <Icon name="x" />
                  </Flex>
                )}
              </Flex>
              <Flex
                sx={{
                  flexDirection: ["column", "row"],
                  justifyContent: ["initial", "space-between"],
                  flexWrap: ["initial", "wrap"],
                  my: 2,
                }}
              >
                <Text
                  sx={{
                    fontSize: "15px",
                    my: [1, 0],
                    flex: [0, "1 0 50%"],
                    maxWidth: ["100%", "50%"],
                  }}
                >
                  {item.product?.title}
                </Text>
                <Text
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    flex: [0, "1 0 50%"],
                    maxWidth: ["100%", "50%"],
                    textAlign: ["start", "end"],
                  }}
                >
                  {formatMoneyAmount(
                    {
                      currencyCode: cart?.region?.currency_code,
                      amount: getVariantPrice(cart, item, true),
                    },
                    0
                  )}
                </Text>
                <Text sx={{ fontSize: "15px", mt: [0, 2] }}>
                  Size: <strong>{item.title}</strong>
                </Text>
              </Flex>
            </Flex>
            <AddToCart variant={item} />
          </Flex>
        )
      })}
    </Flex>
  )
}

export default Wishlist
